





































import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';

import { baConfig } from '../../../../config';
import { ApiSvcOrgResponseDTO, ClientDataApi } from '../../../../generated/api-svc';

@Component({
  components: {
    UiDataTable,
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
  },
})
export default class Client1099List extends BaseVue {
  public isLoading = false;

  public headers = [
    { id: 'type', label: 'Type', defaultVisibility: true, defaultWidth: '30px' },
    { id: 'id', label: 'Client Id', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'actions', label: 'Actions', defaultVisibility: true, defaultWidth: '83px' },
  ];

  public clients: ApiSvcOrgResponseDTO[] = [];

  async mounted() {
    await this.loadClients();
  }

  async loadClients() {
    this.isLoading = true;
    try {
      const clientApi = new ClientDataApi(undefined, baConfig.getFriendlyApiUrl());
      const resp = await clientApi.getClients(this.orgId, { withCredentials: true });
      this.clients = resp.data.items;
    } catch (e: any) {
      this.showErrorSnackbar('Problem loading clients ' + e.message);
    } finally {
      this.isLoading = false;
    }
  }
}
