

























import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import Client1099List from '@/components/clients/1099/Client1099List.vue';
import Exports1099 from '@/components/clients/1099/Exports1099.vue';
import CostBasis from '@/components/clients/tabs/costBasis.vue';
import Transactions from '@/components/clients/tabs/transactions.vue';
import Users from '@/components/clients/tabs/users.vue';
import UiSelect from '@/components/ui/UiSelect.vue';

@Component({
  components: {
    Exports1099,
    Client1099List,
    UiSelect,
    Transactions,
    CostBasis,
    Users,
  },
})
export default class Client1099 extends BaseVue {
  public selectedTab = 'clients';

  public isLoading = false;

  public headers = [
    { id: 'type', label: 'Type', defaultVisibility: true, defaultWidth: '52px' },
    { id: 'id', label: 'Transaction Id', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'date', label: 'Date', defaultVisibility: true, defaultWidth: '196px' },
    { id: 'amounts', label: 'Amounts', defaultVisibility: true, defaultWidth: '94px' },
    { id: 'tokens', label: 'Tokens', defaultVisibility: true, defaultWidth: '83px' },
  ];

  public getTabClass(id: string) {
    if (id === this.selectedTab) {
      return 'tw-border-primary-300 tw-text-gray-700 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
    } else {
      return 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
    }
  }
}
