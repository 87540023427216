



















































import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';

export interface Export {
  id: string;
  created: string;
  createdBy: string;
  name: string;
}

@Component({
  components: {
    UiDataTable,
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
  },
})
export default class Exports1099 extends BaseVue {
  public isLoading = false;

  public exports: Export[] = [
    {
      created: '01-24-2023 11:34:00 AM',
      createdBy: 'Pat White',
      id: '7xhlweRRukwWCU0WWc9P',
      name: '2022 - Draft',
    },
    {
      created: '01-31-2023 5:34:00 PM',
      createdBy: 'Pat White',
      id: 'eGxhCnCq4ab9ckSWg7EU',
      name: '2022 - Final',
    },
  ];

  public headers = [
    { id: 'created', label: 'Created', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'id', label: 'Export Id', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'name', label: 'Name', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'createdBy', label: 'Created By', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'actions', label: 'Actions', defaultVisibility: true, defaultWidth: '83px' },
  ];
}
